import React, { useState } from 'react'
import _ from 'lodash'
import { Divider, Select, Spin } from 'antd'
import styled from 'styled-components'
import HealthCard from '../../healthScore/card'
import Information from '../../UI/extraInformation'
import HideWrap from '../comp/hideWrap'
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryGroup,
  VictoryScatter
} from 'victory'
import moment from 'moment'
import { RangeBar } from '../comp/bodyComp'
import Polynomial from '../comp/polynomial'
import BodyChart, { BodyChatName } from '../comp/bodyChart'
import { Pluralize } from '../../../utils/common'
// import { RangeColors } from '../../../utils/constant'
import { CaretDownOutlined } from '@ant-design/icons'
import { ReactComponent as CycleIcon } from '../../../asserts/icon/arDeck/icon-test-type-cycle.svg'
import { ReactComponent as TreadmillIcon } from '../../../asserts/icon/arDeck/icon-test-type-treadmill.svg'
import { ReactComponent as OtherIcon } from '../../../asserts/icon/arDeck/icon-test-type-other.svg'
import { editAssessmentReivewMetricValue } from '../../../api/assessmentReview'
import {
  AerobicTestTypeCycleIcon,
  AerobicTestTypeOtherIcon,
  AerobicTestTypeTreadmillIcon
} from '../comp/Widgets'
import { useImmer } from 'use-immer'
import ConditionalHideWrap from './ConditionalHidewrap'
import { ReactComponent as CycleShape } from '../../../asserts/icon/CycleShape.svg'
import { ReactComponent as TreadmillShape } from '../../../asserts/icon/TreadmillShape.svg'
import { ReactComponent as OtherShape } from '../../../asserts/icon/OtherShape.svg'

const { Option } = Select

export const ChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 500px;
  }
  & > div:first-child {
    margin-right: 40px;
  }
  & > .chart:last-child {
    margin-right: -40px;
  }
  .tip-label {
    max-width: 600px;
    transform: scale(0.8);
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    font-size: 12px;
    color: rgba(50, 58, 70, 0.5);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: Gilroy;
    white-space: nowrap;
    &:nth-child(2) {
      justify-content: flex-end;
      margin-top: 10px;
    }
    span {
      margin-right: 8px;
      svg {
        vertical-align: middle;
      }
    }
    .tip-circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
    }
    .space {
      display: inline-block;
      width: 10px;
    }
    .tip-line {
      display: inline-block;
      margin-top: -1px;
      width: 8px;
      height: 2px;
      vertical-align: middle;
    }
    &.right-align {
      justify-content: flex-end;
    }
    & > div {
      margin-left: 16px;
    }
  }
  .zones-container {
    font-size: 15px;
    color: #4c6072;
    margin-top: 20px;
    margin-bottom: 28px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 20px;
    & > div {
      padding: 5px 0;
      display: flex;
      justify-content: space-between;
      // &:first-child {
      //   border-bottom: 1px solid #e3e6ea;
      //   margin-bottom: 13px;
      // }
      span {
        display: inline-block;
        min-width: 70px;
        &:nth-child(2) {
          margin-left: 40px;
        }
      }
      .zone {
        min-width: 8px;
        width: 8px;
        height: 18px;
        border-radius: 3px;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
    .hr-max-container {
      display: flex;
      justify-content: space-between;
    }
  }
  .chart-group-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    .ant-divider-vertical {
      height: auto;
    }
    .summary-item {
      min-width: 30%;
      .label {
        color: #4c6072;
        font-size: 15px;
        padding-bottom: 10px;
      }
      .value {
        color: #5c6a7c;
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
  .score-list {
    margin-top: 40px;
    .body-item {
      display: flex;
      justify-content: space-between;
    }
    .score-item {
      padding-bottom: 20px;
      color: #4c6072;
      font-size: 15px;
      border-bottom: 1px solid #e3e6ea;
      &.v2:last-child {
        border: none;
      }
      .r-item {
        margin: 10px 0;
        text-align: right;
      }
      .h-item {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
      }
      .value {
        font-size: 24px;
        font-family: 'Gilroy-Bold';
        &.green {
          color: #40c47c;
        }
        &.red {
          color: #ff6b00;
        }
        .anticon {
          font-size: 14px;
          vertical-align: middle;
          margin-left: 5px;
        }
      }
    }
    .more-select-options {
      display: flex;
      align-items: center;
      margin-bottom: 54px;
      .test-type {
        display: flex;
        align-items: center;
        .option-content {
          display: flex;
          align-items: center;
        }
      }
      .submax {
        margin-left: 60px;
      }
    }
  }
  .peak-rank {
    margin-top: 0;
    .range-item .value {
      margin-top: -15px;
    }
    .score-item {
      border: none;
      .ranges {
        margin-top: 40px;
      }
    }
  }
`

const OptionContainer = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  font-weight: 600;
  svg {
    margin-right: 6px;
  }
`

export default function Aerobic(props) {
  const {
    person,
    hideSectionHandle,
    hideSection,
    // handleSave,
    compareData,
    dataList,
    view,
    loginRole,
    save,
    onScoreChangeHandle,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    ageScopes,
    // arDetail: { notes, status, detail },
    arDetail: { notes, status, id },
    dynamicData: {
      vo2pk,
      submaxTest,
      heartRate,
      lactate,
      dxaSummary,
      bodyCompResults,
      finishTime,
      vo2Peak,
      testType
    }
  } = props
  const data = dataList.find((item) => item.name === 'Aerobic fitness') || {}
  const compare =
    compareData.find((item) => item.name === 'Aerobic fitness') || {}

  const fitness = notes.find((item) => item.pillar === 'fitness')

  return (
    <>
      {(status !== 1 ||
        fitness ||
        heartRate ||
        vo2pk ||
        lactate ||
        dxaSummary ||
        bodyCompResults) && (
        <ConditionalHideWrap
          name="Aerobic Fitness"
          isSection={true}
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
          condition={
            view === 'Draft' || (view === 'Preview' && !hideSection.includes('Aerobic Fitness'))
          }
        >
          <section>
            <h1 className="section-title">Aerobic Fitness</h1>
            <ConditionalHideWrap
              name="Aerobic Fitness - summary"
              title="summary"
              syncStatus={hideSectionHandle}
              status={hideSection}
              view={view}
              condition={
                view === 'Draft' || (view === 'Preview' && !hideSection.includes('Aerobic Fitness - summary'))
              }
            >
              <div className="sub-title">Summary</div>
              <HealthCard
                {...data}
                compare={compare}
                save={save}
                loginRole={loginRole}
                status={view === 'Draft' ? 'Draft' : 'Opened'}
                onChangeHandle={onScoreChangeHandle}
                disEdit={status === 1 || view === 'Preview'}
                cancel={cancelEdit}
              />
              <Information
                title="COMMENT/NOTE"
                disMarkDone={true}
                initialValue={fitness ? fitness.memo : ''}
                status={view === 'Draft' ? 'Draft' : 'Opened'}
                updateCommend={(value, status) =>
                  handleCommendSave(value, 'fitness', status)
                }
                updateEditingCount={updateEditingCount}
              />
              <Divider />
            </ConditionalHideWrap>

            {(vo2pk || heartRate || lactate) && (
              <>
                <ConditionalHideWrap
                  name="Aerobic Fitness - chart group"
                  // title="Chart group"
                  syncStatus={hideSectionHandle}
                  status={hideSection}
                  view={view}
                  condition={
                    view === 'Draft' || (view === 'Preview' && !hideSection.includes('Aerobic Fitness - chart group'))
                  }
                >
                  <div className="sub-title" style={{ height: 30 }}></div>

                  <ChartGroup
                    {...{
                      person,
                      ageScopes,
                      lactate,
                      notes,
                      handleCommendSave,
                      updateEditingCount,
                      view,
                      vo2pk,
                      heartRate,
                      finishTime,
                      vo2Peak,
                      submaxTest,
                      testType
                    }}
                    onMetricValueChanges={async (params) => {
                      console.log(params)
                      await editAssessmentReivewMetricValue(id, params)
                    }}
                  />
                </ConditionalHideWrap>
              </>
            )}
          </section>
        </ConditionalHideWrap>
      )}
    </>
  )
}

function ChartGroup(props) {
  const {
    person,
    ageScopes,
    lactate,
    notes,
    handleCommendSave,
    updateEditingCount,
    view,
    vo2pk,
    heartRate,
    finishTime,
    vo2Peak,
    submaxTest
  } = props

  const groupNote = notes.find((item) => item.pillar === 'aerobic fitness')
  let summary = []
  let showVO2PkTrends = false

  if (vo2Peak) {
    vo2pk.datasets = vo2pk.datasets || []
    summary.push({
      label: 'VO2 Peak',
      value: vo2Peak.value,
      unit: vo2Peak.unit
    })
    summary.push({
      type: 'divider'
    })
    if (vo2pk.datasets[0] && vo2pk.datasets[0].test_date) {
      showVO2PkTrends = true
    }
  }
  if (finishTime) {
    summary.push({
      label: 'Total Exercise Time',
      value: finishTime.value,
      unit: Pluralize('min', finishTime.value || 1)
    })
    summary.push({
      type: 'divider'
    })
  }
  if (lactate) {
    summary.push({
      label: 'HR Max',
      value: lactate.heartRate.max_heart_rate,
      unit: 'bpm'
    })
  }

  const infoContainerStyle = {
    paddingTop: 85,
    paddingLeft: showVO2PkTrends ? 50 : 0,
    width: showVO2PkTrends ? 500 : '100%'
  }
  if (!showVO2PkTrends) {
    infoContainerStyle.marginRight = 0
  }

  const [udpatingTestType, setUdpatingTestType] = useState(false)
  const [udpatingSubmax, setUdpatingSubmax] = useState(false)
  const [testType, setTestType] = useImmer(props.testType)

  return (
    <>
      <ChartContainer>
        <div>
          <div className="chart-group-summary">
            {summary.map((item, index) =>
              item.type === 'divider' ? (
                <Divider key={index} type="vertical" />
              ) : (
                <div key={index} className="summary-item">
                  <div className="label">{item.label}</div>
                  <div className="value">
                    {item.value || '--'} {item.unit}
                  </div>
                </div>
              )
            )}
          </div>
          {vo2Peak && (
            <>
              <Divider />
              <div className="peak-rank score-list">
                <div className="score-item">
                  {/* <div className="body-item">
                    <div className="label">
                      {submaxTest &&
                        submaxTest.level === 'Yes' &&
                        'Submax Test'}
                    </div>
                    <div
                      style={{ fontSize: 15 }}
                    >
                      {submaxTest &&
                        submaxTest.level === 'Yes' &&
                        submaxTest.value}
                    </div>
                  </div> */}
                  <div className="more-select-options">
                    <div className="test-type">
                      Test Type:{' '}
                      <Select
                        labelInValue
                        disabled={view === 'Preview'}
                        bordered={false}
                        defaultValue={testType.value}
                        style={{ width: 130 }}
                        onSelect={async (v, option) => {
                          setUdpatingTestType(true)
                          try {
                            await props.onMetricValueChanges({
                              metric_key: 'test_type',
                              value: v.value
                            })
                            setTestType((draft) => {
                              draft.value = v.value
                            })
                          } finally {
                            setUdpatingTestType(false)
                          }
                        }}
                        suffixIcon={
                          <CaretDownOutlined style={{ color: 'black' }} />
                        }
                        dropdownRender={(node) => <>{node}</>}
                      >
                        <Option value="Cycle" label="Cycle">
                          <OptionContainer>
                            <CycleShape /> Cycle
                          </OptionContainer>
                        </Option>
                        <Option value="Treadmill" label="Treadmill">
                          <OptionContainer>
                            <TreadmillShape style={{ marginLeft: '-2px' }} /> <span style={{ marginLeft: '-3px' }}>Treadmill</span>
                          </OptionContainer>
                        </Option>
                        <Option value="Other" label="Other">
                          <OptionContainer>
                            <OtherShape /> Other
                          </OptionContainer>
                        </Option>
                      </Select>
                      {udpatingTestType && <Spin />}
                    </div>
                    <div className="submax">
                      Submax:{' '}
                      <Select
                        style={{ width: 100 }}
                        disabled={view === 'Preview'}
                        suffixIcon={
                          <CaretDownOutlined style={{ color: 'black' }} />
                        }
                        onSelect={async (value) => {
                          setUdpatingSubmax(true)
                          await props.onMetricValueChanges({
                            metric_key: 'submax_test',
                            value: value
                          })
                          setUdpatingSubmax(false)
                        }}
                        defaultValue={submaxTest.value}
                        bordered={false}
                        options={[
                          {
                            label: 'Yes',
                            value: 'Submax'
                          },
                          {
                            label: 'No',
                            value: 'Maximal'
                          }
                        ]}
                      />
                      {udpatingSubmax && <Spin />}
                    </div>
                  </div>
                  <RangeBar
                    {...vo2Peak}
                    {...{ person, ageScopes }}
                    showRangeArea
                    showRangeLevel
                    colorfulText
                  />
                </div>
              </div>
            </>
          )}
          {lactate && (
            <div className="zones-container">
              <div className="hr-max-container">
                <span>HR Max</span>
                <span>{lactate.heartRate.max_heart_rate} bpm</span>
              </div>
              <div>
                <div>
                  <span className="zone" style={{ background: '#FF3D00' }} />
                  Zone 5
                </div>
                <div>
                  <span>
                    {lactate.heartRate.zones._heartRateZone5.percent * 100}%
                  </span>
                  <span>
                    {lactate.heartRate.zones._heartRateZone5.lowest_heart_rate}+
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <span className="zone" style={{ background: '#FF6847' }} />
                  Zone 4
                </div>
                <div>
                  <span>
                    {lactate.heartRate.zones._heartRateZone4.percent * 100}%
                  </span>
                  <span>
                    {lactate.heartRate.zones._heartRateZone4.lowest_heart_rate}{' '}
                    -{' '}
                    {lactate.heartRate.zones._heartRateZone4.highest_heart_rate}
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <span className="zone" style={{ background: '#FF8933' }} />
                  Zone 3
                </div>
                <div>
                  <span>
                    {lactate.heartRate.zones._heartRateZone3.percent * 100}%
                  </span>
                  <span>
                    {lactate.heartRate.zones._heartRateZone3.lowest_heart_rate}{' '}
                    -{' '}
                    {lactate.heartRate.zones._heartRateZone3.highest_heart_rate}
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <span className="zone" style={{ background: '#FFB570' }} />
                  Zone 2
                </div>
                <div>
                  <span>
                    {lactate.heartRate.zones._heartRateZone2.percent * 100}%
                  </span>
                  <span>
                    {lactate.heartRate.zones._heartRateZone2.lowest_heart_rate}{' '}
                    -{' '}
                    {lactate.heartRate.zones._heartRateZone2.highest_heart_rate}
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <span className="zone" style={{ background: '#FFD466' }} />
                  Zone 1
                </div>
                <div>
                  <span>
                    {lactate.heartRate.zones._heartRateZone1.percent * 100}%
                  </span>
                  <span>
                    {lactate.heartRate.zones._heartRateZone1.lowest_heart_rate}{' '}
                    -{' '}
                    {lactate.heartRate.zones._heartRateZone1.highest_heart_rate}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="chart">
          {vo2pk && person && (
            <VO2Chart data={vo2pk} person={person} testType={testType} />
          )}
        </div>
      </ChartContainer>
      <Divider />
      <ChartContainer>
        <div className="chart">
          {heartRate && <HeartRateChart data={heartRate} />}
        </div>
        <div className="chart">{lactate && <Polynomial {...lactate} />}</div>
      </ChartContainer>
      <ChartContainer>
        {showVO2PkTrends && (
          <div style={{ paddingRight: 10 }}>
            <div className="sub-title" style={{ marginBottom: -25 }}>
              VO2 Peak - Treadmill Regression
            </div>
            <BodyChart
              series={_.cloneDeep(vo2pk.datasets).reverse()}
              name={BodyChatName.VO2PK}
            />
          </div>
        )}
        <div style={infoContainerStyle}>
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            initialValue={groupNote ? groupNote.memo : ''}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            updateCommend={(value, status) =>
              handleCommendSave(value, 'aerobic fitness', status)
            }
            updateEditingCount={updateEditingCount}
          />
        </div>
      </ChartContainer>
    </>
  )
}

export const axisStyles = {
  axis: {
    strokeWidth: 1.5,
    stroke: '#8A969F',
    strokeDasharray: '6, 3'
  },
  grid: {
    strokeWidth: 1.5,
    stroke: 'rgba(138,150,159,.2)',
    strokeDasharray: '6, 3'
  },
  tickLabels: {
    fill: '#8A969F',
    fontSize: 12,
    padding: 10
  },
  axisLabel: {
    fill: '#8A969F',
    fontSize: 12,
    padding: 35
  }
}

function getTrendLinePoint(x, slope, intercept) {
  return { x: x, y: slope * x + intercept }
}

function linearRegression(y, x) {
  let lr = {}
  let n = y.length
  let sum_x = 0
  let sum_y = 0
  let sum_xy = 0
  let sum_xx = 0
  let sum_yy = 0

  for (var i = 0; i < y.length; i++) {
    sum_x += x[i]
    sum_y += y[i]
    sum_xy += x[i] * y[i]
    sum_xx += x[i] * x[i]
    sum_yy += y[i] * y[i]
  }

  lr['slope'] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x)
  lr['intercept'] = (sum_y - lr.slope * sum_x) / n
  lr['r2'] = Math.pow(
    (n * sum_xy - sum_x * sum_y) /
      Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
    2
  )

  return lr
}

function HeartRateChart(props) {
  const axisX = [10, 20, 30, 40, 50, 60],
    axisY = [80, 100, 120, 140, 160, 180, 200, 220]

  function getPointers(series = { hrSeries: [], vo2Series: [] }) {
    const { hrSeries, vo2Series } = series
    const data = []
    if (vo2Series) {
      for (let i = 0; i < vo2Series.length; i++) {
        if (vo2Series[i] && hrSeries[i]) {
          data.push({
            x: vo2Series[i],
            y: hrSeries[i]
          })
        }
      }
    }
    return data
  }

  let dataArr = []
  for (const { testDate, series } of props.data) {
    const data = {
      testDate,
      color: '#BCC2CE',
      size: 3,
      data: getPointers(series)
    }

    if (data.data.length > 0) {
      const { slope, intercept, r2 } = linearRegression(
        data.data.map((item) => item.y),
        data.data.map((item) => item.x)
      )
      data.r2 = r2
      data.lineData = [
        getTrendLinePoint(10, slope, intercept),
        getTrendLinePoint(60, slope, intercept)
      ]
      data.getY = (x) => slope * x + intercept
    }
    dataArr.push(data)
  }
  dataArr = dataArr.reverse()

  if (dataArr[0]) {
    const l = dataArr.length
    dataArr[l - 1].color = '#2B4B8E'
    dataArr[l - 1].size = 5
    if (dataArr[l - 2]) {
      dataArr[l - 2].color = '#758CBB'
    }
  }

  return (
    <>
      <div className="tip-label right-align">
        {dataArr.map((item, index) => (
          <div key={index}>
            <span className="tip-circle" style={{ background: item.color }} />
            {moment(item.testDate).format('M/D/YYYY')}
          </div>
        ))}
      </div>
      <div className="tip-label right-align">
        {dataArr.map((item, index) => (
          <div key={index}>
            <span className="tip-line" style={{ background: item.color }} />
            Timelime for {moment(item.testDate).format('M/D/YYYY')}
            <br />
            <span className="space" />
            {item.r2 && <>r2 = {Math.round(item.r2 * 1000) / 1000}</>}
          </div>
        ))}
      </div>

      <VictoryChart width={600} height={500} maxDomain={{ x: 60, y: 220 }}>
        <VictoryAxis
          tickValues={axisX}
          style={axisStyles}
          label="OXYGEN UPTAKE (ML/KG/MIN)"
        />
        <VictoryAxis
          dependentAxis
          tickValues={axisY}
          style={axisStyles}
          label="HEART RATE (BPM)"
        />

        {dataArr
          .filter((item) => item.data.length > 0)
          .map((item, index) => (
            <VictoryGroup key={index}>
              {item.size === 5 && (
                <VictoryScatter
                  data={item.data}
                  style={{ data: { fill: item.color } }}
                  size={item.size}
                />
              )}
              <VictoryLine
                data={item.lineData}
                style={{ data: { stroke: item.color, strokeWidth: 1.5 } }}
              />
            </VictoryGroup>
          ))}
      </VictoryChart>
    </>
  )
}

function VO2Chart(props) {
  const {
    series: { xAxis, yAxis },
    datasets
  } = props.data
  let age = 30
  if (props.person) {
    age = moment().diff(props.person.profile.date_of_birth, 'years')
  }

  const n = 14,
    step = 5

  let x = age - 5,
    y = 0
  const axisX = [],
    axisY = []
  for (let i = 0; i <= n; i++) {
    axisY.push(y)
    y += step
  }

  for (let i = 0; i <= 10; i++) {
    axisX.push(x)
    x += 1
  }

  function handleData(key) {
    const { series } = yAxis[key]
    const data = []
    for (const index of Object.keys(xAxis.age)) {
      if (xAxis.age[index] >= axisX[0] && xAxis.age[index] <= axisX[10]) {
        data.push({ x: xAxis.age[index], y: series[index] })
      }
    }
    return data
  }

  function handleDaraLinear(key) {
    const { color, series } = yAxis[key]
    const { slope, intercept } = linearRegression(series, xAxis.age)
    const data = [
      getTrendLinePoint(axisX[0], slope, intercept),
      getTrendLinePoint(axisX[10], slope, intercept)
    ]
    const dashLineData = [
      getTrendLinePoint(axisX[7], slope, intercept),
      getTrendLinePoint(axisX[10], slope, intercept)
    ]
    return {
      key,
      color,
      scatterData: handleData(key),
      data,
      dashLineData
    }
  }

  let lineDatas = []
  for (const key of Object.keys(yAxis)) {
    lineDatas.push(handleDaraLinear(key))
  }

  const testData =
    datasets &&
    datasets
      .filter((item) => item.age >= axisX[0] && item.age <= axisX[10])
      .map((item) => ({ x: item.age, y: item.value }))

  const max = (testData && Math.max(...testData.map((item) => item.y))) || 0
  while (max > y - 5) {
    axisY.push(y)
    y += step
  }
  return (
    <>
      <div className="tip-label">
        {Object.keys(yAxis).map((key, index) => (
          <div key={index}>
            <span
              className="tip-circle"
              style={{ background: yAxis[key].color }}
            />
            {key}
          </div>
        ))}
      </div>
      <div className="tip-label">
        <div>
          <span>
            <svg
              width="12"
              height="2"
              viewBox="0 0 12 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                y1="1"
                x2="12"
                y2="1"
                stroke="#989CA2"
                strokeWidth="2"
                strokeDasharray="2 2"
              />
            </svg>
          </span>
          Predicted period
        </div>
        <div>
          <span>
            <SmallPointer />
          </span>
          VO2pk
        </div>
      </div>
      <VictoryChart width={600} height={500}>
        <VictoryAxis
          tickValues={axisX}
          style={axisStyles}
          tickFormat={(t) =>
            [age - 5, age, age + 5].find((item) => item === t) ? t : null
          }
          label="AGE (YEARS)"
        />
        <VictoryAxis
          dependentAxis
          tickValues={axisY}
          style={axisStyles}
          label="OXYGEN UPTAKE (ML/KG/MIN)"
        />
        {lineDatas.map((item, index) => (
          <VictoryGroup key={index}>
            <VictoryLine
              data={item.data}
              style={{
                data: {
                  stroke: item.color,
                  strokeWidth: item.key === 'Average' ? 6 : 1.5
                }
              }}
            />
            {/* <VictoryLine
              data={item.dashLineData}
              key={index}
              style={{
                data: {
                  stroke: item.color,
                  strokeWidth: item.key === 'Average' ? 6 : 1.5,
                  strokeDasharray: '3,3'
                }
              }}
            /> */}
          </VictoryGroup>
        ))}
        {testData && testData.length > 0 && (
          <VictoryScatter
            data={testData}
            dataComponent={renderTestType(props.testType)}
          />
        )}
      </VictoryChart>
    </>
  )
  function renderTestType(type) {
    if (type) {
      switch (type.value) {
        case 'Other':
          return <OtherShape />
        case 'Treadmill':
          return <TreadmillShape />
        case 'Cycle':
          return <CycleShape />

        default:
          return <LargePointer />
      }
    } else {
      return <LargePointer />
    }
  }
}

export function SmallPointer(props) {
  return (
    <svg
      {...props}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8C6.20914 8 8 6.20914 8 4Z"
        fill="#2B4B8E"
      />
      <path
        d="M4 2L4.618 3.23442L6 3.43241L5 4.39321L5.23607 5.75L4 5.10942L2.76393 5.75L3 4.39321L2 3.43241L3.382 3.23442L4 2Z"
        fill="#86C2D8"
      />
    </svg>
  )
}

export function LargePointer(props) {
  const { x, y } = props
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 12, y: y - 12 }}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M24.0204 12.1231C24.0204 5.5585 18.6987 0.236816 12.1341 0.236816C5.56948 0.236816 0.247803 5.5585 0.247803 12.1231C0.247803 18.6877 5.56948 24.0094 12.1341 24.0094C18.6987 24.0094 24.0204 18.6877 24.0204 12.1231Z"
          fill="#2B4B8E"
        />
        <path
          d="M12.135 6.18164L13.9715 9.8498L18.0782 10.4381L15.1066 13.2933L15.8081 17.3251L12.135 15.4215L8.46197 17.3251L9.16347 13.2933L6.19189 10.4381L10.2986 9.8498L12.135 6.18164Z"
          fill="#86C2D8"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="23.7726"
            height="23.7726"
            fill="white"
            transform="translate(0.248047 0.237793)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
